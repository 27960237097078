.navbar {
    background: transparent;
    width: 100%;
    padding: 10px;
}
body{
    position: relative;
    // background-image: url('./assets/images/home.jpg');
    // background-size: cover;
    background-color: #ededee !important;
    color: #898989 !important;
    //background-color: #8f7193 !important;
    // background-color: #e5dde6 !important;
}
.toolbar {
    background-color: #898989 !important;
}
.principalHome{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/home.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.principalHome2{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/explore.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.principalHome3{
    position: relative;
    background-color: rgb(71, 71, 71);
    background-image: url('./assets/images/explore2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
.icon-svg{
    fill: white;
}
.btn-active {
    background: #3182ce !important;
    color: white !important;
}